import React from 'react';
import { Formik } from 'formik';
import {Checkbox, Input} from "semantic-ui-react";

import '../../containers/helper.css';



const UserForm = (props) => {

    let form = <p>Loading form....</p>;
    if (props.formSchema !== undefined){
        const formSchema = props.formSchema;

        const bindSubmitForm = props.bindSubmitForm;
        const bindSubmitting = props.bindSubmitting;


        form = <Formik
            initialValues={props.intialValues}
            validate={(values) => {
                let errors = {};
                //Check for required
                formSchema.forEach((field) => {
                    let fieldValue = values[field.code]
                    if (field.required && !fieldValue) {
                        errors[field.code] = 'Required';
                    } else if ( !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fieldValue) && field.type === "email") {
                        errors[field.code] = 'Invalid email address';
                    }
                });

                if (Object.keys(errors).length) {
                    bindSubmitting(false);
                }
                return errors
            }}
            onSubmit={(values, {setSubmitting}) => {
                // console.log(values);
                props.onSubmit(values).then(() => {
                    //props.onSubmit(values)
                }).then(() => {
                    //alert("WORD?!@#");
                }).catch(() => {

                    //alert("SO BROKEN!@?#");
                }).finally(() =>{
                    //alert("Finally1?#");
                    setSubmitting(false);
                    bindSubmitting(false) //Really DON"T LIKE THIS!

                })
            }}

        >
            {props => {
                const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                } = props;

                //hotwire the submitForm to a parent Control
                //https://jaredpalmer.com/formik/docs/guides/form-submission
                if (!Object.keys(errors).length){
                    //Not sure how I like this part
                    bindSubmitForm(props.submitForm);
                }
                let fields = formSchema.map((field, index) => {

                    //Generate input field here. The default is strong input
                    let fieldHtml = "";
                    switch(field.type) {
                        case "bool": {
                            fieldHtml = <Checkbox toggle id={field.code}
                                checked={values[field.code] || false}
                                onChange={handleChange}
                                onBlur={handleBlur}/>
                            break;
                        }
                        default: {
                            let fieldType = /^text|email|tel|phoneNumber|emailAddress|number$/.test(field.type)?field.type:"text";
                            fieldHtml = <Input fluid placeholder={field.placeholder}
                                id={field.code}
                                type={fieldType}
                                value={values[field.code] || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                   errors[field.code] && touched[field.code] ? 'text-input error' : 'text-input'
                                } />
                        }
                    }


                    return <div key={index} className='field'>
                        <label>
                            {field.title}
                        </label>
                        {fieldHtml}
                        {errors[field.code] && touched[field.code] && (
                            <div className="input-feedback">{errors[field.code]}</div>
                        )}
                    </div>
                });

                return (
                    <form onSubmit={handleSubmit} className='ui form'>
                        {fields}
                    </form>
                );
            }}
        </Formik>
    }
    return(
        form
    );
};

export default UserForm;