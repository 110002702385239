import React, { useContext }  from 'react'
import {Button, Card, List} from 'semantic-ui-react';
import Icon from "../../Icon/Icon";
import EventContext from "../../../context/event-context";
import DateDisplay from "../../DateDisplay/DateDisplay";
import StaticMap from "../StaticMap/StaticMap"
import Badge from "../Badge/Badge";
import DateModal from "../../DateModal/DateModal";
import moment from "moment";
import AddToCalendarHOC from "react-add-to-calendar-hoc"


const Information = (props) => {

    const event = useContext(EventContext);


    let extraAddressInfo = '';
    if(event.addressDetails){
        extraAddressInfo = <div className='extra-address-info'>{event.addressDetails}</div>
    }


    const AddToCalendarModal = AddToCalendarHOC(Button, DateModal);

    const startDatetime = moment(event.eventDate,"DD MMM YYYY 'at' LT");
    const endDatetime = startDatetime.clone().add(1, 'hours');
    const duration = ''+moment.duration(endDatetime.diff(startDatetime)).asHours();
    const calendarEvent = {
        description: event.description,
        duration,
        endDatetime: endDatetime.format('YYYYMMDDTHHmmss'),
        location: event.streetAddress,
        startDatetime: startDatetime.format('YYYYMMDDTHHmmss'),
        title: event.title,
    };

    // console.log("calendarEvent",calendarEvent);


    return (
            <Card fluid>

                <Card.Content className='event-information'>
                    <List>
                        <List.Item>
                            <List.Icon>
                                <Icon  size="1.4em"  icon='calendar-insert' />
                            </List.Icon>
                            <List.Content>
                                <List.Header>Event</List.Header>
                                <List.Description>
                                    <div className='event-information__date'>
                                        <DateDisplay date={event.eventDate} includeTime={true}/>
                                    </div>
                                    <AddToCalendarModal
                                        className='event-information__calendar-button'
                                        linkProps={{
                                            className: 'ui fluid basic button',
                                        }}
                                        event={calendarEvent}
                                    />

                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon>
                                <Icon size="1.4em"  icon='map-marker' />
                            </List.Icon>
                            <List.Content>
                                <List.Header>Address</List.Header>
                                <List.Description>
                                    {event.streetAddress}
                                    {extraAddressInfo}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    </List>
                </Card.Content>
                {event.gpsLocation ?
                    <StaticMap geoLocation={event.gpsLocation} streetAddress={event.streetAddress} linkToMap={true} showMarker={true} />
                    : null
                }
                {event.accountName ?
                    <Card.Content className='event-information'>
                        <Badge title={'Hosted by '+event.accountName}
                               email={event.contactEmailAddress}
                               phone={event.contactPhoneNumber}
                               website={event.accountWebsite}
                               logo={event.accountLogo}
                        />
                    </Card.Content>
                    : null
                }

            </Card>
    )
};

export default Information;